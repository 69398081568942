import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="৪০৪ - বালের পেজ নট ফাউন্ড" mdxType="SEO" />
    <h1 {...{
      "id": "ধ্যাত-বাল"
    }}>{`ধ্যাত বাল!`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.458333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHa8hiqSiP/xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIDERIh/9oACAEBAAEFAoJQXhhK6zOm0f/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAICAwAAAAAAAAAAAAAAAAABETIQITH/2gAIAQEABj8ChSVOFjbeP//EAB4QAAICAAcAAAAAAAAAAAAAAAABESEQMUFRcYHR/9oACAEBAAE/IYuW3ZFoDSfbNQLgzRd4f//aAAwDAQACAAMAAAAQND//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPxBH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QV//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExUXFBsdH/2gAIAQEAAT8QcVztLL59QYCZ2W9zzh7+kBhmGgQWcsbcLVVVvmf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/0480deb5c54a127a58363b79346d53cb/d3be9/404_unknown_source.webp 480w"],
            "sizes": "(max-width: 480px) 100vw, 480px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/0480deb5c54a127a58363b79346d53cb/7cc5e/404_unknown_source.jpg 480w"],
            "sizes": "(max-width: 480px) 100vw, 480px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/0480deb5c54a127a58363b79346d53cb/7cc5e/404_unknown_source.jpg",
            "alt": "404 unknown source",
            "title": "source: fb/unknown",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>
    <p>{`বালের পেজ নট ফাউন্ড।`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      